import paymentService from "../../../../services/paymentService";

export default {
    name: "make-wallet-empty",
    components: {},
    props: [],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        submitWallet(address) {
            paymentService.requestForSettle(address).then(() => {
                this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
                    title: this.$i18n.t("toast.successTitle"),
                    noCloseButton: true,
                    variant: "custom",
                    bodyClass:
                        "successFontColor rtl text-right font-weight-bold py-3",
                    headerClass:
                        "successBackgroundColor successFontColor  rtl text-right",
                    solid: false,
                });
            });
        },
    },
};
